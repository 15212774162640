import { EntitySearchResult } from '@pp/common/components/entity-name-search/entity-search.interface'
import { FilterType } from '@pp/common/components/filters/filter.store'
import { arrayFromString } from '@pp/common/helpers/utils'
import { DateType } from '@pp/modules/analyse/common/profile-header/components/default-filters/default-filters.type'
import { GrantStatus, Route } from '@pp/modules/search/advanced-search/tabs/filings/filings-search.store'
import {
  RadiusUnit,
  RadiusValue,
  FLimitingClauseType,
  SearchParameters,
} from '@pp/modules/search/advanced-search/typescript/search.interface'
import { RootStoreInterface } from '@pp/store/root.store'
import chroma from 'chroma-js'
import _ from 'lodash'
import { makeAutoObservable } from 'mobx'

import { SortObject, SortOrder, TechOverlapResult } from './common/techoverlap.interface'
import { FamilyType } from '../../analyse/common/types/app.type'
import { TargetType } from '../mutuality/mutuality.store'

export type SearchFilter = FilterType & {
  applicant_location_distance?: number[]
  applicant_location_distance_unit?: string[]
  applicant_location_lat?: number[]
  applicant_location_lon?: number[]
  applicant_location_name?: string[]
  family_type: FamilyType[]
  focal_rep_country: string[]
  focal_rep_url: string[]
  relevant_count_from?: number[]
  relevant_count_to?: number[]
  rep_location_distance?: number[]
  rep_location_distance_unit?: string[]
  rep_location_lat?: number[]
  rep_location_lon?: number[]
  rep_location_name?: string[]
  target_rep_country: string[]
  target_rep_url: string[]
}

const newFilters = [
  { prop: 'direction', value: FLimitingClauseType.Direction },
  { prop: 'focal_direction', value: FLimitingClauseType.Direction },
  { prop: 'target_direction', value: FLimitingClauseType.Direction },
  { prop: 'applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'focal_applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'target_applicant_country', value: FLimitingClauseType.ApplicantCountry },
  { prop: 'rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'focal_rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'target_rep_country', value: FLimitingClauseType.RepCountry },
  { prop: 'sequence', value: FLimitingClauseType.Sequence },
  { prop: 'focal_sequence', value: FLimitingClauseType.Sequence },
  { prop: 'target_sequence', value: FLimitingClauseType.Sequence },
  { prop: 'legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'focal_legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'target_legal_state', value: FLimitingClauseType.LegalState },
  { prop: 'grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'focal_grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'target_grant_status', value: FLimitingClauseType.GrantStatus },
  { prop: 'route', value: FLimitingClauseType.Route },
  { prop: 'focal_route', value: FLimitingClauseType.Route },
  { prop: 'target_route', value: FLimitingClauseType.Route },
  { prop: 'appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'focal_appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'target_appln_type', value: FLimitingClauseType.ApplicationType },
  { prop: 'nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'focal_nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'target_nice_class', value: FLimitingClauseType.NiceClass },
  { prop: 'nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'focal_nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'target_nice_category', value: FLimitingClauseType.NiceCategory },
  { prop: 'verbal_element', value: FLimitingClauseType.VerbalElement },
  { prop: 'focal_verbal_element', value: FLimitingClauseType.VerbalElement },
  { prop: 'target_verbal_element', value: FLimitingClauseType.VerbalElement },
  { prop: 'lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'focal_lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'target_lifecycle', value: FLimitingClauseType.Lifecycle },
  { prop: 'decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'focal_decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'target_decision_maker', value: FLimitingClauseType.DecisionMaker },
  { prop: 'int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'focal_int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'target_int_decision_maker', value: FLimitingClauseType.InternationalDecisionMaker },
  { prop: 'applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'focal_applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'target_applicant_origin', value: FLimitingClauseType.ApplicantOrigin },
  { prop: 'applicant_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'focal_applicant_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'target_applicant_url', value: FLimitingClauseType.ApplicantUrl },
  { prop: 'int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'focal_int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'target_int_rep_url', value: FLimitingClauseType.InternationalRepUrl },
  { prop: 'int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'focal_int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'target_int_appln_auth', value: FLimitingClauseType.InternationalApplnAuth },
  { prop: 'int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'focal_int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'target_int_publn_auth', value: FLimitingClauseType.InternationalPublnAuth },
  { prop: 'int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'focal_int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'target_int_rep_country', value: FLimitingClauseType.InternationalRepCountry },
  { prop: 'dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'focal_dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'target_dom_rep_url', value: FLimitingClauseType.DomesticRepUrl },
  { prop: 'dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'focal_dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'target_dom_appln_auth', value: FLimitingClauseType.DomesticApplnAuth },
  { prop: 'dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'focal_dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'target_dom_publn_auth', value: FLimitingClauseType.DomesticPublnAuth },
  { prop: 'dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'focal_dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'target_dom_rep_country', value: FLimitingClauseType.DomesticRepCountry },
  { prop: 'foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'focal_foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'target_foreign_rep_url', value: FLimitingClauseType.ForeignRepUrl },
  { prop: 'foreign_appln_auth', value: FLimitingClauseType.ForeignApplnAuth },
  { prop: 'focal_foreign_appln_auth', value: FLimitingClauseType.ForeignApplnAuth },
  { prop: 'target_foreign_appln_auth', value: FLimitingClauseType.ForeignApplnAuth },
  { prop: 'foreign_publn_auth', value: FLimitingClauseType.ForeignPublnAuth },
  { prop: 'focal_foreign_publn_auth', value: FLimitingClauseType.ForeignPublnAuth },
  { prop: 'target_foreign_publn_auth', value: FLimitingClauseType.ForeignPublnAuth },
  { prop: 'foreign_rep_country', value: FLimitingClauseType.ForeignRepCountry },
  { prop: 'focal_foreign_rep_country', value: FLimitingClauseType.ForeignRepCountry },
  { prop: 'target_foreign_rep_country', value: FLimitingClauseType.ForeignRepCountry },
  { prop: 'representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'focal_representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'target_representative_type', value: FLimitingClauseType.RepresentativeType },
  { prop: 'publn_auth', value: FLimitingClauseType.PublnAuth },
  { prop: 'focal_publn_auth', value: FLimitingClauseType.PublnAuth },
  { prop: 'target_publn_auth', value: FLimitingClauseType.PublnAuth },
  { prop: 'techn_field_nr', value: FLimitingClauseType.TechnField },
  { prop: 'focal_techn_field_nr', value: FLimitingClauseType.TechnField },
  { prop: 'target_techn_field_nr', value: FLimitingClauseType.TechnField },
]

const initialSearchParameters: SearchParameters = {
  entityGeoBoundsName: 'Washington, D.C.',
  entityGeoBoundsHigh: [38.9958641, -76.909393],
  entityGeoBoundsLow: [38.7916449, -77.119759],
  entityGeoBoundsRadius: RadiusValue._0,
  entityGeoBoundsRadiusUnit: RadiusUnit.MI,
  entityCountry: [],
  primaryAttributeAuthority: [],
  primaryAttributeCountry: [],
  primaryAttributeGeoBoundsName: 'Washington, D.C.',
  primaryAttributeGeoBoundsHigh: [38.9958641, -76.909393],
  primaryAttributeGeoBoundsLow: [38.7916449, -77.119759],
  primaryAttributeGeoBoundsRadius: RadiusValue._0,
  primaryAttributeGeoBoundsRadiusUnit: RadiusUnit.MI,
  date_type: 'm',
  date_from: new Date().getFullYear() - 3,
  date_to: new Date().getFullYear(),
  filingReceivedFromCountry: [],
  filingReceivedFromPublAuth: [],
  filingReceivedFromGeoBoundsName: 'Washington, D.C.',
  filingReceivedFromGeoBoundsHigh: [38.9958641, -76.909393],
  filingReceivedFromGeoBoundsLow: [38.7916449, -77.119759],
  filingReceivedFromGeoBoundsRadius: RadiusValue._0,
  filingReceivedFromGeoBoundsRadiusUnit: RadiusUnit.MI,
  filingSentToCountry: [],
  filingSentToPublnAuth: [],
  filingSentToGeoBoundsName: 'Washington, D.C.',
  filingSentToGeoBoundsHigh: [38.9958641, -76.909393],
  filingSentToGeoBoundsLow: [38.7916449, -77.119759],
  filingSentToGeoBoundsRadius: RadiusValue._0,
  filingSentToGeoBoundsRadiusUnit: RadiusUnit.MI,
  receivedCasesApplicantDecision: true,
  sentCasesApplicantDecision: true,
  secondLevelCasesApplicantDecision: true,
  representative_type: [],
  appln_type: [],
  rep_location: [],
  nice_class: [],
  lifecycle: [],
  nice_category: [],
  verbal_element: [],
  ipc_class: [],
  techn_field_nr: [],
  sequence: [],
  grant_status: [],
  route: [],
  legal_state: [],
  relevant_count_from: [],
  relevant_count_to: [],
  applicant_country: [],
  direction: [],
  applicant_origin: [],
  applicant_url: [],
  rep_url: [],
  rep_country: [],
  publn_auth: [],
  int_appln_auth: [],
  int_decision_maker: [],
  dom_publn_auth: [],
  dom_rep_country: [],
  dom_rep_url: [],
  int_publn_auth: [],
  int_rep_country: [],
  int_rep_url: [],
  foreign_rep_url: [],
  foreign_appln_auth: [],
  foreign_publn_auth: [],
  foreign_rep_country: [],
}

export interface TechOverlapSearchInterface {
  focalFirms: EntitySearchResult[]
  ipc4SortBy: SortObject
  ipc8Results: TechOverlapResult[] | null
  ipc8SortBy: SortObject
  limitingClauses: FLimitingClauseType[]
  results: TechOverlapResult[] | null
  searchParameters: SearchParameters
  showIpc8Results: boolean
  showTechnicalFields: boolean
  targetFirms: EntitySearchResult[]
  targetType: TargetType
  technicalFieldsSortBy: SortObject
  technicalSectorsSortBy: SortObject
}

export class TechOverlapSearchStore implements TechOverlapSearchInterface {
  private rootStore: RootStoreInterface

  focalFirms: EntitySearchResult[] = []
  targetFirms: EntitySearchResult[] = []
  searchParameters = initialSearchParameters
  limitingClauses: FLimitingClauseType[] = []
  results: TechOverlapResult[] | null = null
  ipc8Results: TechOverlapResult[] | null = null
  sectorResults: TechOverlapResult[] | null = null
  fieldResults: TechOverlapResult[] | null = null
  showIpc8Results: boolean
  showTechnicalFields: boolean
  activeIpc4s: string[] = []
  activeIpc8s: string[] = []
  activeTechnicalFields: string[] = []
  activeTechnicalSectors: string[] = []
  ipc4SortBy: SortObject = { tech_overlap_count: SortOrder.desc }
  ipc8SortBy: SortObject = { tech_overlap_count: SortOrder.desc }
  technicalFieldsSortBy: SortObject = { tech_overlap_count: SortOrder.desc }
  technicalSectorsSortBy: SortObject = { tech_overlap_count: SortOrder.desc }
  targetType: TargetType = 'LF'

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore
    this.showIpc8Results = localStorage.getItem('showIpc8LFResults') === 'true' || false
    this.showTechnicalFields = localStorage.getItem('showLFTechnicalFields') === 'true' || false

    makeAutoObservable(this)
  }

  setTargetType = (targetType: TargetType) => (this.targetType = targetType)

  setIpc4SortBy = (sortBy: SortObject) => {
    this.ipc4SortBy = sortBy
  }

  setIpc8SortBy = (sortBy: SortObject) => {
    this.ipc8SortBy = sortBy
  }

  setTechnicalFieldsSortBy = (sortBy: SortObject) => {
    this.technicalFieldsSortBy = sortBy
  }

  setTechnicalSectorsSortBy = (sortBy: SortObject) => {
    this.technicalSectorsSortBy = sortBy
  }

  setFocalFirms = (focalFirms: EntitySearchResult[]) => {
    this.focalFirms = focalFirms
  }

  setTargetFirms = (targetFirms: EntitySearchResult[]) => {
    this.targetFirms = targetFirms
  }

  setResults = (results: TechOverlapResult[] | null) => {
    this.results = results
  }

  setIpc8Results = (ipc8Results: TechOverlapResult[] | null) => {
    this.ipc8Results = ipc8Results
  }

  setSectorResults = (sectorResults: TechOverlapResult[] | null) => {
    this.sectorResults = sectorResults
  }

  setFieldResults = (fieldResults: TechOverlapResult[] | null) => {
    this.fieldResults = fieldResults
  }

  setLimitingClauses = (clauses: FLimitingClauseType[]) => (this.limitingClauses = clauses)

  setShowIpc8Results = (showIpc8Results: boolean) => {
    this.showIpc8Results = showIpc8Results
    localStorage.setItem('showIpc8LFResults', '' + showIpc8Results)
    this.updateSearchParamsFromFilters(this.serverFilters as SearchFilter)
  }

  setShowTechnicalFields = (showTechnicalFields: boolean) => {
    this.showTechnicalFields = showTechnicalFields
    localStorage.setItem('showLFTechnicalFields', '' + showTechnicalFields)
    this.updateSearchParamsFromFilters(this.serverFilters as SearchFilter)
  }

  setActiveIpc4 = (ipc4: string[]) => {
    this.activeIpc4s = ipc4
  }

  setActiveIpc8 = (ipc8: string[]) => {
    this.activeIpc8s = ipc8
  }

  setActiveTechnicalFields = (technicalFields: string[]) => {
    this.activeTechnicalFields = technicalFields
  }

  setActiveTechnicalSectors = (technicalSectors: string[]) => {
    this.activeTechnicalSectors = technicalSectors
  }

  setSearchParameter = (searchParameter: {}) => {
    this.searchParameters = Object.assign({}, this.searchParameters, searchParameter)
  }

  unsetSearchParameter = (searchParameter: string) => {
    this.searchParameters[searchParameter] = initialSearchParameters[searchParameter]
  }

  resetSearchUI = () => {
    this.results = null
    this.sectorResults = null
    this.fieldResults = null
    this.ipc8Results = null
    this.limitingClauses = []
    this.searchParameters = initialSearchParameters

    const routerStore = this.rootStore.routerStore
    routerStore.replace(routerStore.location.pathname)
  }

  get mappedResults() {
    const scale = chroma.scale(['#FFBF00', '#85C1E9']).colors(1000)
    return (
      this.results?.map((result, index) => ({
        ...result,
        color: scale[index],
      })) || null
    )
  }

  get mappedIpc8Results() {
    const scale = chroma.scale(['#FFBF00', '#85C1E9']).colors(1000)
    return (
      this.ipc8Results?.map((result, index) => ({
        ...result,
        color: scale[index],
      })) || null
    )
  }

  get mappedSectorResults() {
    const scale = chroma.scale(['#FFBF00', '#85C1E9']).colors(5)
    return (
      this.sectorResults?.map((result, index) => ({
        ...result,
        color: scale[index],
      })) || null
    )
  }

  get mappedFieldResults() {
    const scale = chroma.scale(['#FFBF00', '#85C1E9']).colors(35)
    return (
      this.fieldResults?.map((result, index) => ({
        ...result,
        color: scale[index],
      })) || null
    )
  }

  get serverFilters() {
    const filter: SearchFilter = {
      family_type: this.rootStore.applicationStore.familyType,
      focal_rep_url: this.focalFirms.map((focalFirm) => focalFirm.value),
      focal_rep_country: this.focalFirms.map((focalFirm) => focalFirm.country),
      target_rep_url: this.targetFirms.map((targetFirm) => targetFirm.value),
      target_rep_country: this.targetFirms.map((targetFirm) => targetFirm.country),
      date_from: [this.searchParameters.date_from.toString()],
      date_to: [this.searchParameters.date_to.toString()],
      date_type: [this.searchParameters.date_type.toUpperCase() as DateType],
    }

    // IPCs
    filter.ipc_1 = [] as string[]
    filter.ipc_3 = [] as string[]
    filter.ipc_4 = [] as string[]
    filter.ipc_8 = [] as string[]
    for (const entry of this.searchParameters.ipc_class) {
      if (entry?.length === 1) {
        filter.ipc_1.push(entry)
      } else if (entry?.length === 3) {
        filter.ipc_3.push(entry)
      } else if (entry?.length === 4) {
        filter.ipc_4.push(entry)
      } else {
        filter.ipc_8.push(entry)
      }
    }

    // Technical field
    if (this.limitingClauses.includes(FLimitingClauseType.TechnField)) {
      let technicalFields: string[] = []
      for (const element of this.searchParameters.techn_field_nr) {
        if (Number.isNaN(Number(element))) {
          const elements = element.split(',')
          technicalFields = [...technicalFields, ...elements]
        } else {
          technicalFields.push(element)
        }
      }
      filter.techn_field_nr = technicalFields
    }

    // Grant Status
    filter.grant_status = this.limitingClauses.includes(FLimitingClauseType.GrantStatus)
      ? [this.searchParameters.grant_status ? GrantStatus.GRANTED : GrantStatus.NOT_GRANTED]
      : []

    // Route
    filter.route = this.limitingClauses.includes(FLimitingClauseType.Route)
      ? [this.searchParameters.sequence ? Route.PCT : Route.DIR]
      : []

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newFilters.forEach((el) => (filter[el.prop] = this.searchParameters[el.prop]))

    return _.omitBy(filter, _.isEmpty)
  }

  updateSearchParamsFromFilters = (filters: SearchFilter) => {
    const language = `locale=${this.rootStore.applicationStore.language}`
    const targetType = `target_type=${this.targetType}`
    const searchStrings: string[] = [language, targetType]
    searchStrings.push(`showIpc8Results=${this.showIpc8Results}`)
    searchStrings.push(`showTechnicalFields=${this.showTechnicalFields}`)

    if (this.limitingClauses.length > 0) {
      searchStrings.push(`limitingClauses=${this.limitingClauses}`)
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      } else {
        searchStrings.push(`${key}=${value}`)
      }
    })

    if (this.rootStore.applicationStore.resultsGrouping === 'group') {
      searchStrings.push('group_search=true')
    }

    const routerStore = this.rootStore.routerStore
    routerStore.replace(`${routerStore.location.pathname}?${searchStrings.join('&')}`)
  }

  getSearchParamsFromFilters = (filters: SearchFilter) => {
    const language = `locale=${this.rootStore.applicationStore.language}`
    const targetType = `target_type=${this.targetType}`
    const searchStrings: string[] = [language, targetType]
    searchStrings.push(`showIpc8Results=${this.showIpc8Results}`)
    searchStrings.push(`showTechnicalFields=${this.showTechnicalFields}`)

    if (this.limitingClauses.length > 0) {
      searchStrings.push(`limitingClauses=${this.limitingClauses}`)
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        searchStrings.push(`${key}=${value && value.join(',')}`)
      } else {
        searchStrings.push(`${key}=${value}`)
      }
    })

    if (this.rootStore.applicationStore.resultsGrouping === 'group') {
      searchStrings.push('group_search=true')
    }

    return searchStrings.join('&')
  }

  initializeSearchFiltersFromUrl = (queryParameters: string) => {
    const urlSearchParams = new URLSearchParams(queryParameters)

    const targetType = urlSearchParams.get('target_type')
    if (targetType) this.targetType = targetType as TargetType

    const familyType = urlSearchParams.get('family_type')
    if (familyType) this.rootStore.applicationStore.setFamilyType(familyType.split(',') as FamilyType[])

    const showIpc8Results = urlSearchParams.get('showIpc8Results')
    if (showIpc8Results === 'true') {
      this.showIpc8Results = urlSearchParams.get('showIpc8Results') === 'true' || false
    }

    const showTechnicalFields = urlSearchParams.get('showTechnicalFields')
    if (showTechnicalFields) {
      this.showTechnicalFields = urlSearchParams.get('showTechnicalFields') === 'true' || false
    }

    const limitingClauses = urlSearchParams.get('limitingClauses')
    if (limitingClauses) {
      const splitLimitingClauses = limitingClauses.split(',')
      this.limitingClauses = splitLimitingClauses as FLimitingClauseType[]
    }

    this.searchParameters.date_from = urlSearchParams.get('date_from')
      ? Number(urlSearchParams.get('date_from'))
      : initialSearchParameters.date_from

    this.searchParameters.date_to = urlSearchParams.get('date_to')
      ? Number(urlSearchParams.get('date_to'))
      : initialSearchParameters.date_to

    this.searchParameters.date_type = urlSearchParams.get('date_type') || initialSearchParameters.date_type

    this.searchParameters.group_search = urlSearchParams.get('group_search') ? true : undefined

    // IPCs
    if (this.limitingClauses.includes(FLimitingClauseType.IPC)) {
      const ipc1 = arrayFromString(urlSearchParams.get('ipc_1'))
      const ipc3 = arrayFromString(urlSearchParams.get('ipc_3'))
      const ipc4 = arrayFromString(urlSearchParams.get('ipc_4'))
      const ipc8 = arrayFromString(urlSearchParams.get('ipc_8'))

      this.searchParameters.ipc_class = [...ipc1, ...ipc3, ...ipc4, ...ipc8]
    }

    // Technical field
    if (this.limitingClauses.includes(FLimitingClauseType.TechnField)) {
      this.searchParameters.techn_field_nr =
        arrayFromString(urlSearchParams.get('techn_field_nr')) || initialSearchParameters.techn_field_nr
    }

    // Grant Status
    if (this.limitingClauses.includes(FLimitingClauseType.GrantStatus)) {
      this.searchParameters.grant_status =
        urlSearchParams.get('grant_status') && urlSearchParams.get('grant_status') === GrantStatus.GRANTED
          ? [GrantStatus.GRANTED]
          : [GrantStatus.NOT_GRANTED]
    }

    // Route
    if (this.limitingClauses.includes(FLimitingClauseType.Route)) {
      this.searchParameters.sequence =
        urlSearchParams.get('route') && urlSearchParams.get('route') === Route.PCT ? [Route.PCT] : [Route.DIR]
    }

    newFilters.forEach((el) => {
      if (this.limitingClauses.includes(el.value)) {
        this.searchParameters[el.prop] =
          this.arrayFromString(urlSearchParams.get(el.prop)) || initialSearchParameters[el.prop]
      }
    })

    return this.serverFilters
  }

  private arrayFromString(str: string | null): string[] {
    if (str) {
      const splitStr = str.split(',')
      return splitStr
    } else {
      return []
    }
  }
}
