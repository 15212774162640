import { useState, useEffect, useMemo } from 'react'

import { css } from '@emotion/css'
import { t } from '@lingui/macro'
import { Trans } from '@lingui/react'
import { Tooltip } from '@pp/common/chart/components/chart-holder/components'
import { ResultsGrouping as ResultsGroupingType } from '@pp/modules/analyse/common/types/app.type'
import { useStores } from '@pp/store/useStore.hook'
import { observer } from 'mobx-react-lite'
import { Icon as MuiIcon } from 'semantic-ui-react'

const labelStyleCss = css`
  color: $4c4c4c;
  font-size: 12.5px;
  margin: 0 4px;
`

const switchCss = (toggled: boolean) => css`
  color: ${toggled ? '#96c347' : '#ccc'};
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  font-size: 16px;
`

type Props = {
  minimized: boolean
}

const ResultsGrouping = ({ minimized }: Props) => {
  const { applicationStore, routerStore } = useStores()

  const search = routerStore.location.search || ''
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [resultsGroupingValue, setResultsGroupingValue] = useState<ResultsGroupingType>(
    applicationStore.resultsGrouping,
  )
  const isToggled = resultsGroupingValue === 'group'

  const handleClickSwitch = () => {
    const newValue: ResultsGroupingType = isToggled ? 'single' : 'group'

    setResultsGroupingValue(newValue)
    applicationStore.setResultsGrouping(newValue)

    if (newValue === 'group') {
      searchParams.set('group_search', 'true')
      routerStore.replace(`${routerStore.location.pathname}?${searchParams.toString()}`)
    } else if (newValue === 'single') {
      searchParams.delete('group_search')
      routerStore.replace(`${routerStore.location.pathname}?${searchParams.toString()}`)
    }
  }

  useEffect(() => {
    if (searchParams.get('group_search') === 'true') {
      setResultsGroupingValue('group')
    }
  }, [searchParams])

  useEffect(() => {
    if (applicationStore.resultsGrouping !== resultsGroupingValue) {
      setResultsGroupingValue(applicationStore.resultsGrouping)
    }
  }, [applicationStore.resultsGrouping, resultsGroupingValue])

  return (
    <div
      className={css`
        padding: 0 16px 16px 16px;
      `}
    >
      <div
        className={css`
          display: flex;
          flex-direction: ${minimized ? 'column' : 'row'};
          justify-content: center;
          align-items: center;
        `}
      >
        {!minimized && (
          <Tooltip
            testId="sidebar.results-grouping-tooltip"
            tooltip={t`sidebar.resultsGrouping.tooltip`}
            position="right center"
            margin="0"
            noFamilyType
          />
        )}
        <p className={labelStyleCss}>
          <Trans id={minimized ? 'sidebar.resultsGrouping.shortLabel' : 'sidebar.resultsGrouping.longLabel'} />
        </p>
        <button className={switchCss(isToggled)} onClick={() => handleClickSwitch()}>
          <MuiIcon name={isToggled ? 'toggle on' : 'toggle off'} />
        </button>
      </div>
    </div>
  )
}

export default observer(ResultsGrouping)
