import React from 'react'

import { t } from '@lingui/macro'
import { observer } from 'mobx-react-lite'
import { Popup } from 'semantic-ui-react'

import { useStores } from '../../../../../store/useStore.hook'
import Icon from '../../../../components/icon/Icon'

export const getSufixFromFamilyType = (familyType: string[]) => {
  if (familyType.includes('P') && !familyType.includes('T')) {
    return 'pat'
  } else if (familyType.includes('T') && familyType.includes('P')) {
    return 'ip'
  } else if (familyType.includes('T')) {
    return 'tm'
  }
}

type Props = {
  margin?: string
  noFamilyType?: boolean
  position?:
    | 'top left'
    | 'top right'
    | 'bottom right'
    | 'bottom left'
    | 'right center'
    | 'left center'
    | 'top center'
    | 'bottom center'
  testId: string
  tooltip: string
}

const Tooltip = ({ testId, tooltip, position = 'top center', margin = '3px 0 0 4px', noFamilyType = false }: Props) => {
  const { applicationStore } = useStores()
  const familyType = applicationStore?.familyType
  const translationKey = noFamilyType ? tooltip : t({ id: `${tooltip}.${getSufixFromFamilyType(familyType)}` })

  return (
    <Popup
      hoverable
      position={position}
      trigger={<Icon iconName="icon-question-mark" size={14} margin={margin} />}
      data-test-id={`${testId}-tooltip`}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: translationKey,
        }}
      />
    </Popup>
  )
}

export default observer(Tooltip)
