import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import environment from '@pp/environments'
import fetch from 'cross-fetch'

const httpLink = new HttpLink({ uri: environment.graphqlApiUrl, fetch })

const authMiddleware = () =>
  new ApolloLink((operation, forward) => {
    const token = localStorage.token
    if (token) operation.setContext({ headers: { authorization: `Bearer ${token}` } })
    return forward(operation)
  })

const client = new ApolloClient({
  link: authMiddleware().concat(httpLink),
  queryDeduplication: false,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getOrganizationUsers: {
            merge(_existing, incoming) {
              return incoming
            },
          },
        },
      },
    },
  }),
})

export default client
